<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="/logo.png">
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 pt-3"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Verify your account!
          </b-card-title>
          <b-card-text class="mb-2">
            Please checkout your email for the verification PIN number & activation link
          </b-card-text>
          <b-card-text class="text-center mt-2">
            <span>Already verified? </span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;Login here</span>
            </b-link>
          </b-card-text>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BCardText, BCardTitle, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      pinNumber: '',
      token: null,
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.pinNumber = this.$route.params.pin
    this.token = this.$route.params.token
    this.verify()
  },
  methods: {
    verify() {
      this.$http.post('/verify-email', {
        code: this.pinNumber,
      }, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => {
        if (res.data.value.status === 1) {
          const user = res.data.value
          this.$store.commit('auth/SET_AUTHENTICATED', true)
          this.$store.commit('auth/SET_USER', user)
          this.$store.commit('auth/UPDATE_TOKEN', user.token)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'E-Mail verified successfully!',
              icon: 'SuccessIcon',
              variant: 'success',
            },
          })
          window.location.href = '/';
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'E-Mail failed verification!',
              icon: 'ErrorIcon',
              variant: 'error',
            },
          })
        }
      }).catch(() => {
        this.$store.commit('auth/SET_USER', [])
        this.$store.commit('auth/SET_AUTHENTICATED', false)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
